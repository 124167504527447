export { default as NOT_FOUND_LOTTIE } from "@shared/assets/data/404-error.json"
export { default as KEYBOARD_INPUT_LOTTIE } from "@shared/assets/data/keyboard-input.json"
export { default as NETWORK_ERROR_LOTTIE } from "@shared/assets/data/network-error.json"
export { default as SERVER_RUNNING_LOTTIE } from "@shared/assets/data/server-ldata.json"
export { default as SmsLoading } from "@shared/assets/data/sms-loading.json"
export { default as SOMETHING_WENT_WRONG } from "@shared/assets/data/something-went-wrong.json"

export const IMAGES = {
	Bkash_payment_instructions: require("@shared/assets/img/bkash-pmt.jpeg")
		.default,
	Light_bg: require("@shared/assets/img/light_bg.jpg"),
	UN_AUTH_ILLUSTRATION: require("@shared/assets/img/unauth-illustraction.svg")
		.default,
	MAP: require("@shared/assets/img/google-map.jpg").default,
	USER_IMAGE: require("@shared/assets/img/user.png").default,
	IMAGE_NOT_FOUND: require("@shared/assets/img/image-not-found.png").default,

	LOGO: require("@shared/assets/img/aag.png").default,
	AUTH_BG: require("@shared/assets/img/bg.svg").default,
}

export const DOCUMENTS = {
	RESELLER_TERMS_PDF: require("@shared/assets/pdf/reseller-form.pdf"),
}
