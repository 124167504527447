import OtpInput from "react-otp-input"
import React from "react"

interface PropsIF {
	value?: string
	onChange?: any
	numInputs?: number
}

export const OtpInputField: React.FC<PropsIF> = ({
	value,
	onChange,
	numInputs,
}) => {
	return (
		<div className="verify-code-blk">
			<OtpInput
				value={value}
				onChange={onChange}
				numInputs={numInputs}
				separator={<span>-</span>}
			/>
		</div>
	)
}
