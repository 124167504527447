import {
	CoreAxiosInstanceV2,
	MutationConfig,
	queryClient,
} from "@shared/config"

import { ISmsMaskDelete } from "@shared/interfaces"
import { useMutation } from "react-query"

const END_POINT = "/smsMasks/"

export const SmsMaskServiceV2 = {
	NAME: "SmsMaskService",
	userDelete(payload: ISmsMaskDelete): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}userDelete`, payload)
	},
}
interface SmsMaskUserDelete {
	config?: MutationConfig<typeof SmsMaskServiceV2.userDelete>
}

export const useSmsMaskUserDelete = ({ config }: SmsMaskUserDelete) => {
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.userDelete,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.userDelete}`
			)
		},
	})
}
