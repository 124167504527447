import React, { lazy } from "react"

import { RouteObject } from "react-router"

const ProfileAccountPage = lazy(() => import("./ProfileAccountPage"))
const ResellerRequestPage = lazy(() => import("./ResellerRequestPage"))
const UsersPage = lazy(() => import("./UsersPage"))

export const UsersRoutes: RouteObject[] = [
	{ path: "", element: <UsersPage /> },
	{ path: "me", element: <ProfileAccountPage /> },
	{ path: "reseller-request", element: <ResellerRequestPage /> },
]
