import { Card } from "antd"
import Chart from "react-apexcharts"
import { Purify } from "@shared/components"
import React from "react"

interface IFProps {
	labels?: string[]
	count?: number[]
	loading?: boolean
}
const MaskingWiseChart: React.FC<IFProps> = ({ labels, count, loading }) => {
	return (
		<Card style={{ minHeight: 300 }} title="Masking Wise">
			<Purify
				loading={loading}
				empty={labels?.length === 0 || count?.length === 0}>
				<div style={{ justifyContent: "center", display: "flex" }}>
					<Chart
						options={
							{
								chart: {
									width: 280,
									height: 280,
									type: "pie",
									labels: {
										itemMargin: {
											horizontal: 5,
											vertical: 0,
										},
									},
								},
								legend: {
									position: "bottom",
								},
								labels: labels,

								colors: ["#0089ff", "#EC0944"],
							} as any
						}
						series={count}
						type="pie"
						width={300}
					/>
				</div>
			</Purify>
		</Card>
	)
}

export default MaskingWiseChart
