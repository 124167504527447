import { CoreAxiosInstanceV2 } from "./../config/axios/core.axios.v2"
import { MutationConfig } from "@shared/config"
import { RUNNING_TASK_SESSION_KEY } from "@modules/campaign/enums"
import { notification } from "antd"
import { storage } from "@shared/utils"
import { useMutation } from "react-query"
const END_POINT = "/userRunningTasks/"

export const UserRunningService = {
	findById(taskId: string) {
		return CoreAxiosInstanceV2.get(`${END_POINT}${taskId}`)
	},
}
interface RunningTask {
	config?: MutationConfig<typeof UserRunningService.findById>
}

export const useRunningTask = ({ config }: RunningTask) => {
	return useMutation({
		...config,
		mutationFn: UserRunningService.findById,
		onSuccess: async (res) => {
			if (res?.data?.data?.hasError) {
				storage.removeData(RUNNING_TASK_SESSION_KEY)
				notification.error({ message: res?.data?.data?.errorName })
			} else if (res?.data?.data.runningStatus === "COMPLETED") {
				storage.removeData(RUNNING_TASK_SESSION_KEY)
			}
		},
	})
}
