export const ENV = {
	// END POINTS
	ANDROMEDA_END_POINT: 'http://139.59.125.104:5555/andromeda/',
	ANTARES_END_POINT: 'http://139.59.125.104:3011/api/v1/',
	ENTERPRISE_END_POINT: 'http://139.59.125.104:3011/api/v1',
	SOCKET_API_END_POINT: 'http://139.59.125.104:3011',
	FILE_DOWNLOAD_URL: 'http://139.59.125.104:5555/csv/',
	SENTRY_DNS:
		'https://a2aa6fad21bf41ee8e422cdee82bc7ad@o871183.ingest.sentry.io/5966393',
	REFRESH_TOKEN_EXPIRE_TIME: 300000,
	RUNNING_TASK_REFETCH_TIME: 4000,
	AUTO_REFETCH_INTERVAL_TIME: 10000,
	USER_META_DATA_SESSION_KEY: `META_DATA`,
	CUSTOM_PROPERTY_DETECTOR: '###',
	NOTIFICATION_BLACKLIST_END_POINT: [
		'userMetaDatas',
		'refreshToken',
		'costCount',
	],
	DEFAULT_AVATAR:
		'https://i.ibb.co/ctbQ9x1/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thu.png',
}
