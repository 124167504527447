import { createSelector } from "reselect"

const state = (state: any) => state.userState

export const selectCurrentUserInfo = createSelector(
	[state],
	(state: any) => state.currentUserInfo
)
export const selectSelectedUser = createSelector(
	[state],
	(state: any) => state?.selectedUser
)
