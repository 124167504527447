import {
	AuthIndividualService,
	AuthResellerService,
	AuthService,
} from '@shared/services'

import { AxiosResponse } from 'axios'
import { storage } from '@shared/utils'
import { useMutation } from 'react-query'
export const useAuth = () => {
	//?User Login
	const individualLoginFn = useMutation(AuthIndividualService.login, {
		onSuccess: (val: any) => {
			if (val?.data?.success) {
				storage.setToken(val?.data?.data?.accessToken?.token)
				storage.setRole(val?.data?.data?.user?.type)
				window.location.pathname = '/'
			}
		},
	})
	//?User Password Reset
	const individualUserResetPasswordFn = useMutation(
		AuthIndividualService.resetPassword
	)
	//? Reseller Login
	const resellerLoginFn = useMutation(AuthResellerService.login, {
		onSuccess: (val: any) => {
			if (val?.data?.success) {
				storage.setToken(val?.data?.data?.accessToken?.token)
				storage.setRole(val?.data?.data?.user?.type)
				window.location.pathname = '/'
			}
		},
	})
	//? User Create By Reseller
	const individualUserRegisterByResellerFn = useMutation(
		AuthResellerService.register
	)
	//? Verify FP
	const forgetPasswordCheckFn = useMutation(AuthService.forgetPasswordCheck)
	const forgetPasswordConfirmFn = useMutation(AuthService.forgetPasswordConfirm)
	const changePasswordFn = useMutation(AuthService.changePassword)
	//? Logout
	const logoutFn = () => {
		storage.clear()
		window.location.assign(window.location.origin as unknown as string)
	}
	//? Refresh Token
	const refreshTokenFn = useMutation(AuthService.refreshToken, {
		onSuccess: (val: AxiosResponse) => {
			storage.setToken(val?.data?.data.token)
		},
	})
	const isAuthenticated = storage.getToken() ? true : false
	return {
		individualLoginFn,
		resellerLoginFn,
		logoutFn,
		isAuthenticated,
		refreshTokenFn,
		individualUserResetPasswordFn,
		individualUserRegisterByResellerFn,
		forgetPasswordCheckFn,
		forgetPasswordConfirmFn,
		changePasswordFn,
	}
}
