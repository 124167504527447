import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from '@shared/config'
import {
	IGetMask,
	IGetUserMaskX,
	IUserGetMaskX,
} from './../interfaces/user.interfaces'
import {
	IUpdate,
	IUserPackageUpdate,
	IUserStatusUpdate,
	IUserUpdate,
} from '@shared/interfaces'
import { concatQuery, getSessionUtil } from '@shared/utils'
import { useMutation, useQuery } from 'react-query'

import { IHttpResponse } from './../interfaces/base.interface'

const END_POINT = '/users/'
export const UsersServiceV2 = {
	NAME: 'UsersService',
	uploadImage(payload: FormData): Promise<IHttpResponse> {
		return CoreAxiosInstanceV2.post(`${END_POINT}uploadImage`, payload, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	},
	update({ id, payload }: IUpdate<IUserUpdate>): Promise<IHttpResponse> {
		return CoreAxiosInstanceV2.put(`${END_POINT}${id}`, payload)
	},
	user(id: string): Promise<IHttpResponse> {
		return CoreAxiosInstanceV2.get(`${END_POINT}${id}`)
	},
	packUpdate(payload: IUserPackageUpdate): Promise<IHttpResponse> {
		return CoreAxiosInstanceV2.post(`${END_POINT}packageAssign`, payload)
	},
	getUserMask(id: string, options: IGetUserMaskX): Promise<IHttpResponse> {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}getMask/${id}?${concatQuery(options)}`
		)
	},
	bulkUpdateStatus(payload: IUserStatusUpdate): Promise<IHttpResponse> {
		return CoreAxiosInstanceV2.post(`${END_POINT}bulkUpdateStatus`, payload)
	},
	getMask({ id, options }: IGetMask): Promise<IHttpResponse> {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}getMask/${id}?${concatQuery(options)}}`
		)
	},
}

//?User Upload image
type UseUploadImageProps = MutationConfig<typeof UsersServiceV2.uploadImage>
export const useUserUploadImage = (config: UseUploadImageProps) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.user}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.uploadImage,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

//? User Update
type UseUserUpdateProps = MutationConfig<typeof UsersServiceV2.update>
export const useUpdateUser = (config: UseUserUpdateProps) => {
	return useMutation({
		...config,
		mutationFn: (val) => {
			return UsersServiceV2.update(val)
		},
		onSettled: () => {
			queryClient.invalidateQueries(
				`${UsersServiceV2.NAME}${UsersServiceV2.user.name}`
			)
		},
	})
}

//? Get User
type IUseUserProps = {
	config?: QueryConfig<typeof UsersServiceV2.user>
	user?: string
}
export const useUser = ({ config, user }: IUseUserProps) => {
	const KEY = `${UsersServiceV2.NAME}${UsersServiceV2.user.name}`
	const tokenUser = getSessionUtil()
	const id = user || tokenUser.id
	return useQuery({
		...config,
		queryKey: [KEY, user],
		queryFn: () => UsersServiceV2.user(id),
	})
}

//? User Package Update
type UserPackUpdateProps = MutationConfig<typeof UsersServiceV2.packUpdate>
export const useUserPackageUpdate = (config: UserPackUpdateProps) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.packUpdate.name}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.packUpdate,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

//? Get User Mask
type UseUserMasksProps = {
	config?: QueryConfig<typeof UsersServiceV2.getUserMask>
	options?: IGetUserMaskX
	id?: string
}
export const useUserMasks = ({ config, options, id }: UseUserMasksProps) => {
	const QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.getUserMask.name}`
	return useQuery({
		...config,
		queryKey: [QUERY_KEY, Object.values(options), id],
		queryFn: () => UsersServiceV2.getUserMask(id, options),
	})
}

//? User Status Update
type UseStatusUpdateProps = MutationConfig<
	typeof UsersServiceV2.bulkUpdateStatus
>
export const useUseStatusUpdate = (config: UseStatusUpdateProps) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.bulkUpdateStatus.name}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.bulkUpdateStatus,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

//? Get User Available Masks
type UseUserAvailableMaskProps = {
	config?: QueryConfig<typeof UsersServiceV2.getMask>
	options?: IUserGetMaskX
	id?: string
}
export const useUserAvailableMasks = ({
	config,
	options,
	id,
}: UseUserAvailableMaskProps) => {
	const QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.getMask.name}`
	return useQuery({
		...config,
		queryKey: [QUERY_KEY, Object.values(options), id],
		queryFn: () => UsersServiceV2.getMask({ id, options }),
	})
}
