export const Name = "Name"
export const PhoneNumber = "Phone Number"
export const Designation = "Designation"
export const Company = "Company"
export const Address = "Address"
export const Email = "Email"
export const YourCurrentEmailAddress = "Your Current Email Address"
export const OldPassword = "Old Password"
export const NewPassword = "New Password"
export const AccountType = "Account Type"
export const Status = "Status"
export const Joining = "Joining"
export const Number = "Number"

export const NoDataFound = "No Data Found"
export const CREATE_RESELLER = "Create Reseller"
export const CREATE_NEW_ACCOUNT = "Create new Account"
export const USER_LOGIN = "User Login"
export const RESELLER_LOGIN = "Reseller Login"
export const INVALID_NUMBER = "Invalid Number"

export const CREATED_SUCCESS = "Created Successfully"
export const UPDATED_SUCCESS = "Updated Successfully"

export const ARE_YOU_SURE_TO_UPDATE_PROFILE_INFO =
	"Are you sure to Update Profile Info?"
export const ARE_YOU_SURE_TO_RESET_PASSWORD = "Are you sure to reset Password?"

export const REGISTER_SUCCESSFULLY = "Register Successfully"
export const LOGIN_SUCCESSFULLY = "Login Successfully"
export const LOG_OUT_SUCCESSFULLY = "logout Successfully"
export const COPIED_SUCCESSFULLY = "Copied Successfully"

export const PERSONAL_INFO = "Personal Info"
export const SECURITY_UPDATE = "Security Update"
export const AUTOMATONS = "Automatons"
export const CREATE_INDIVIDUAL_USER = "Create Individual User"

export const PASTE_NUMBER_EXAMPLE =
	"Example:01923534635,01923534635,01923534635"

export const PASTE_NUMBER = "Paste Number"
export const USERS = "Users"
export const USER_UPDATE = "User Update"

export const PHON_NUMBER = "Phone Number"
export const COMPANY = "Company"
export const ADDRESS = "Address"
export const YOUR_CURRENT_EMAIL_ADDRESS = "Your Current Email Address"
export const OLD_PASSWORD = "Old Password"
export const NEW_PASSWORD = "New Password"
export const CONFIRM_PASSWORD = "Confirm Password"
export const ACCOUNT_TYPE = "Account Type"
export const STATUS = "Status"
export const DESIGNATION = "Designation"
export const JOINING = "Joining"
export const NUMBER = "Number"

export const GENERATED = "Generated"
export const ARE_YOU_SURE = "Are you sure ?"

export const CONFIRM = "Confirm"
export const CANCEL = "cancel"

export const MASK_ASSIGN = "Mask Assign"
export const MASK_LIST = "Mask List"

export const PACKAGE_UPDATE = "Package Update"
export const MASK_UPDATE = "Mask Rate"
export const LAST_UPDATE = "Last Update"
export const NON_MASK_RATE = "Non Mask Rate"
export const Update = "Update"
export const BALANCE_UPDATE = "Balance Update"
export const AMOUNT = "Amount"
export const EVENT_TYPE = "Event Type"
export const REMARKS = "Remarks"
export const DESCRIPTION = "Description"
export const TRX_REF = "Trx Ref"
export const PASSWORD_DOES_NOT_MATCH = "Password doesn't match"
export const PASSWORD_UPDATE = "Password Update"
export const OVERVIEW = "Overview"
export const API_KEY = "Api Key"
export const SMPP = "SMPP"
export const PLEASE_SELECT_A_COLUMN = "Please Select a Column"
export const YOUR_API_KEY_IS_SAFE = "Your API Key  is safe!"
export const COPY = "Copy to clipboard"
export const ComingSoon = "Not available for you"
export const Dashboard = "Dashboard"
export const Campaign = "Campaign"
export const Contacts = "Contacts"
export const Sms = "SMS"
export const Voice = "Voice"
export const WhatsApp = "WhatsApp"
export const Viber = "Viber"
export const Analytics = "Analytics"
export const Request = "Request"
export const ApiDocs = "API Docs"
export const Billing = "Billing"
export const Manages = "Manages"
export const Automation = "Automation"
export const Users = "Users"
export const HelpAndSupport = "Help & Support"
export const Overview = "Overview"
export const CampaignsOverview = "Campaigns Overview"
export const InstantSms = "Instant SMS"
export const Templates = "Templates"
export const Masking = "Masking"
export const Reseller = "Reseller"
export const Reports = "Reports"
export const CreateCampaign = "Create Campaign"
export const LogInWithEmail = "Log in with email"
export const YourAssignMaskList = "Your  Mask List"
export const ChooseYourChannel = "Choose Your Channel"
export const ChooseYourCampaignChannel = "Choose Your Campaign Channel"
export const SendForm = "Send Form/Masking"
export const EnterNumber = "Enter Number"
export const FromContactsList = "From Contacts List"
export const ChooseYourRecipientFormExistingContacts =
	"Choose your recipient form existing Contacts"
export const FromSegments = "From Segments"
export const ChooseYourRecipientFormExistingSegments =
	"Choose your recipient form existing segments."
export const UploadContactsFile = "Upload Contacts"
export const ContactFileWithBody = "Upload Contacts with Body"
export const PasteNumber = "Paste Number"
export const ManuallyTypeYourNumber = "Manually Type Your Number"
export const UploadContactWithCustomParameterized =
	"Upload Contact File with Custom Parameter"
export const TargetedSMS = "Targeted SMS"
export const ChooseTheCriteriaForYourCampaign =
	"Choose the criteria for your campaign"
export const SendSMSToSingleRecipient = "Send SMS to Single Recipient"
export const UploadYourRecipientUsingCSV = "Upload Your Recipient using CSV"
export const UploadYourRecipientAndBodyUsingCSV =
	"Upload Your Recipient And Body using CSV"
export const UploadYourRecipientWithCustomParameter =
	"Upload Your Recipient with Custom Parameter"
