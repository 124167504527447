//! This file contain two component

import { Button, Card, Input, Space, Table } from "antd"
import { ISms, ISmsLog } from "@shared/interfaces"
import React, { useState } from "react"
import { smsEncodedUtil, statusToJsx } from "@shared/utils"

import { SmsDetails } from "@shared/components"
import { useDebounceFn } from "ahooks"

const { Search } = Input

interface IFProps {
	smsItems?: ISms[]
	onSearch?: (term: string) => void
	loading?: boolean
	pagination?: { page: number; take: number; current: number; total: number }
	onTableChange?: (pagination, filter, sorter) => void
}
const LiveLogTable: React.FC<IFProps> = ({
	smsItems,
	onSearch,
	loading,
	pagination,
	onTableChange,
}) => {
	const { run } = useDebounceFn(
		(term: any) => {
			onSearch(term)
		},
		{
			wait: 500,
		}
	)
	const tableData: any = smsItems?.map((sms: ISmsLog, idx: number) => ({
		key: idx,
		campaignName: sms?.campaign?.name || "INSTANT SMS",
		sendFrom: sms?.mask?.mask || "N/A",
		recipient: sms?.recipient,
		body: smsEncodedUtil(sms?.body?.slice(0, 8)),
		status: statusToJsx(sms?.deliveryStatus, "SUCCESS"),
		actions: <TableActions sms={sms} />,
	}))
	return (
		<Card
			style={{ minHeight: 600 }}
			title="Live Logs"
			extra={
				<Space wrap>
					<Search
						placeholder="Search "
						allowClear
						enterButton="Search"
						size="middle"
						onChange={(e: any) => {
							run(e.target.value)
						}}
					/>
				</Space>
			}>
			<Table
				scroll={{ x: 700 }}
				sticky
				loading={loading}
				// pagination={{
				// 	onChange: (e: any) => onChangePage(e),
				// 	pageSize: 7,
				// 	total: totalItems,
				// 	showSizeChanger: false,
				// }}
				pagination={{
					pageSize: pagination.take,
					current: pagination.current,
					total: pagination.total,
					showSizeChanger: true,
				}}
				onChange={onTableChange}
				columns={columns}
				dataSource={tableData}
			/>
		</Card>
	)
}
const TableActions = ({ sms }: any) => {
	const [uiState, setUiState] = useState({
		viewDetailsOpen: false,
	})
	const { viewDetailsOpen } = uiState

	return (
		<>
			<Button
				type="primary"
				onClick={() => setUiState({ ...uiState, viewDetailsOpen: true })}>
				View
			</Button>
			<SmsDetails
				smsId={sms.id}
				open={viewDetailsOpen}
				onClose={() => setUiState({ ...uiState, viewDetailsOpen: false })}
			/>
		</>
	)
}

const columns: any = [
	{
		title: "Campaign",
		dataIndex: "campaignName",
		width: 270,
	},
	{
		title: "Send From",
		dataIndex: "sendFrom",
		width: 170,
	},
	{
		title: "Recipient",
		dataIndex: "recipient",
		width: 170,
	},
	{
		title: "Body",
		dataIndex: "body",
		width: 100,
	},
	{
		title: "Status",
		dataIndex: "status",
		width: 100,
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "right",
		width: 80,
		fixed: "right",
	},
]
export default LiveLogTable
