import { AppLayoutReducer } from "@shared/redux/layout"
import { combineReducers } from "redux"
import { contactReducer } from "@shared/redux/contact"
import { instantSmsReducer } from "@shared/redux/insert-sms"
import { persistReducer } from "redux-persist"
import { smsCampaignReducer } from "@shared/redux/sms-campaign"
import storage from "redux-persist/lib/storage"
import { userReducer } from "@shared/redux/user"
const persistConfig = {
	key: "root",
	storage,
	whitelist: ["tracking"],
}

const appReducer = combineReducers({
	userState: userReducer,
	smsCampaignState: smsCampaignReducer,
	instantSms: instantSmsReducer,
	appLayout: AppLayoutReducer,
	contact: contactReducer,
})

const rootReducer = (state: any, action: any) => {
	return appReducer(state, action)
}
export default persistReducer(persistConfig, rootReducer)
