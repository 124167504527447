import { CSVReader } from "react-papaparse"
import React from "react"

interface IFProps {
	onChange: (data: string[], file: File) => void
}
export const CSVParser: React.FC<IFProps> = ({ onChange }) => {
	const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {}

	const handleOnRemoveFile = (data: any) => {}

	const handleOnDrop = (data: any, file: any) => {
		onChange(data, file)
	}

	return (
		<>
			<CSVReader
				onDrop={handleOnDrop}
				onError={handleOnError}
				style={{}}
				accept=".csv"
				config={{
					header: true,
					skipEmptyLines: true,
					dynamicTyping: true,
				}}
				addRemoveButton
				onRemoveFile={handleOnRemoveFile}>
				<span>Drop CSV file here or click to upload.</span>
			</CSVReader>
		</>
	)
}
