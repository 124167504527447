import { Navigate, RouteObject } from "react-router"
import React, { lazy } from "react"

import { Paths } from "@shared/constant"

const MaskingRequestPage = lazy(() => import("./MaskingRequestPage"))

export const MaskingRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.InstantMessage} /> },
	{ path: "request", element: <MaskingRequestPage /> },
]
