import { MutationConfig, queryClient } from "@shared/config"

import { CoreAxiosInstanceV2 } from "./../config/axios/core.axios.v2"
import { ICreateResellerRequest } from "@shared/interfaces"
import { useMutation } from "react-query"

const END_POINT = "/resellerRequests/"

export const ResellerRequestServiceV2 = {
	NAME: "ResellerRequestService",
	create(payload: ICreateResellerRequest): any {
		return CoreAxiosInstanceV2.post(END_POINT, payload)
	},
}
interface CreateResellerRequest {
	config?: MutationConfig<typeof ResellerRequestServiceV2.create>
}

export const useCreateResellerRequest = ({ config }: CreateResellerRequest) => {
	return useMutation({
		...config,
		mutationFn: ResellerRequestServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ResellerRequestServiceV2.NAME}${ResellerRequestServiceV2.create.name}`
			)
		},
	})
}
