export const pathVariable = {
	recipient: ":recipient/",
}
export const externalRoutePath = {
	whatsApp: "http://whatsapp.yourdomain.com/",
}

class _Paths {
	//? AUTH
	public Auth = "/auth/"
	public AuthIndividualLogin = `${this.Auth}login/`
	public AuthResellerLogin = `${this.Auth}reseller/`
	public forgotPassword = `${this.Auth}forget-password/`

	//? AUTOMATONS
	public Automatons = "/automatons/"
	public AutomatonsList = `${this.Automatons}`

	//? BILLINGS
	public Billing = "/billings/"
	public TransactionsLogs = `${this.Billing}`

	//? CAMPAIGN
	public Campaign = "/campaign/"
	public CampaignOverview = `${this.Campaign}overview`
	public CreateSmsCampaign = `${this.Campaign}sms/create`
	public SmsCampaignDetail = `${this.Campaign}sms/`

	//? COMMON
	public Common = "/common/"
	public ApiDocs = `${this.Common}api-docs`
	public UpComing = `${this.Common}up-coming`
	public Help = `${this.Common}help`
	public Policy = `${this.Common}policy`

	//? CONTACT
	public Contact = "/contact/"
	public ContactList = `${this.Contact}`

	//? DASHBOARD
	public Dashboard = "/dashboard/"
	public DefaultDashboard = `${this.Dashboard}default`

	//? INSTANT-SMS
	public InstantSms = "/instant/"
	public InstantMessage = `${this.InstantSms}sms/01`

	//? MASKINGS
	public Maskings = "/maskings/"
	public MaskingRequest = `${this.Maskings}request`

	//? PAYMENTS
	public Payments = "/payments/"
	public BkashPayments = `${this.Payments}/bkash`

	//? REPORTS
	public Reports = "/reports/"
	public ReportList = `${this.Reports}`

	//? SMS
	public Sms = "/sms/"
	public SmsOverview = `${this.Sms}overview`

	//? USERS
	public Users = "/users/"
	public UsersMe = `${this.Users}me`
	public UsersList = `${this.Users}`
	public UsersResellerRequest = `${this.Users}reseller-request`

	//? TEMPLATES
	public Templates = "/templates/"
	public SmsTemplates = `${this.Templates}sms`
	public SmsTemplatesList = `${this.Templates}sms/list`
}

export const Paths = new _Paths()
