import React, { useState } from "react"

import AsyncSelect from "react-select/lib/Async"
import { useUserMasks } from "@shared/services"

interface IFProps {
	onChange: (e: any) => void
	label?: string
	userId?: string
	disabled?: boolean
	value?: any
}
export const DebounceMaskSelectField: React.FC<IFProps> = ({
	onChange,
	label = "Send Form",
	userId,
	disabled = false,
	value,
}) => {
	const [searchTerm, setSearchTerm] = useState("")
	const useUserMasksQuery = useUserMasks({
		id: userId,
		options: { maskingType: "ALL", searchTerm: searchTerm },
	})
	const defaultOptions = useUserMasksQuery.data?.data?.data?.map(
		(edge: any) => ({
			label: edge?.smsMask?.mask,
			value: edge,
		})
	)
	const loadOptionsCall = (inputValue: any) => {
		setSearchTerm(inputValue)
	}
	const filter = (inputValue: string) => {
		loadOptionsCall(inputValue)
		const normalizeUser = useUserMasksQuery.data?.data?.data?.map(
			(edge: any) => ({
				label: edge?.smsMask?.mask,
				value: edge,
			})
		)
		return normalizeUser
	}
	const loadOptions = (inputValue: any, callback: any) => {
		setTimeout(() => {
			callback(filter(inputValue))
		}, 1000)
	}
	const onChangeOption = (arrive: any) => {
		onChange(arrive?.value)
	}
	return (
		<>
			<label>{label}</label>
			<AsyncSelect
				className="async-select-field"
				isLoading={useUserMasksQuery.isLoading}
				loadOptions={loadOptions}
				cacheOptions={false}
				clearValue={true}
				defaultOptions={defaultOptions}
				isMulti={false}
				onChange={onChangeOption}
				isDisabled={disabled}
				value={value}
				isClearable={true}
				// defaultValue={defaultValue}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					border: "none",
					boxShadow: "none",
					colors: {
						...theme.colors,
					},
				})}
			/>
		</>
	)
}
