import { ENV } from "../../../ENV"
import axios from "axios"
import { storage } from "@shared/utils"

const headers = {
	"Content-Type": "application/json",
	"X-Request-ID": 124,
	"X-Country-Code": "BD",
	"X-Client-Name": "CRM",
	"X-Client-Version": 123,
	Authorization: `Bearer ${storage.getToken()}`,
}

export const AndromedaAxiosInstance = axios.create({
	baseURL: ENV.ANDROMEDA_END_POINT,
	timeout: 60000,
	headers,
})

AndromedaAxiosInstance.interceptors.request.use(
	(config) => {
		config.headers["Authorization"] = `Bearer ${storage.getToken()}`
		config.headers["X-Request-ID"] = "CRM-WEB-STAGING"
		config.headers["X-Country-Code"] = "BD"
		config.headers["X-Client-Name"] = "CRM-WEB"
		config.headers["X-Client-Version"] = "1.0"
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

AndromedaAxiosInstance.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error?.response?.status === 401) {
			storage.clear()
			window.location.assign((window.location.origin as unknown) as string)
		} else {
			return error
		}
	}
)
