import { CSSTransition } from 'react-transition-group'
import React from 'react'

interface IFProps {
	visible: boolean
	children: JSX.Element
}
export const ActiveTransition: React.FC<IFProps> = ({ visible, children }) => {
	return (
		<CSSTransition
			in={visible}
			timeout={500}
			classNames='css-trans-secondary '
			unmountOnExit>
			<div>{children}</div>
		</CSSTransition>
	)
}
