import { Empty, Spin, Tag } from "antd"

import { CSSProperties } from "react"
import { Status } from "@shared/enums"

interface Props {
	loading?: boolean
	empty?: boolean
	hide?: "*" | "loader" | "empty"
	children?: any
	style?: CSSProperties
}
export const Purify: React.FC<Props> = ({
	empty = false,
	loading = false,
	hide,
	children,
	style,
}) => {
	return (
		<>
			{loading === true ? (
				hide === "loader" || hide === "*" ? (
					<span></span>
				) : (
					<div className="h-60 grid place-content-center" style={style}>
						<Spin className="content-center" />
					</div>
				)
			) : empty === true ? (
				hide === "empty" || hide === "*" ? (
					<span></span>
				) : (
					<div style={style}>
						<Empty className="content-center" />
					</div>
				)
			) : (
				children
			)}
		</>
	)
}

export const statusToJsx = (status: string, positive: string) => {
	return status?.toLowerCase() === positive.toLowerCase() ? (
		<Tag color="#87d068">{status}</Tag>
	) : (
		<Tag color="#ef8e07">{status}</Tag>
	)
}
export const boolToJsxUtil = (status: boolean) => {
	return status === true ? (
		<Tag color="#87d068">Yes</Tag>
	) : (
		<Tag color="#f50">No</Tag>
	)
}

export const orderStatusJsx = (status: Status) => {
	if (status === "PENDING") {
		return (
			<Tag style={{ borderRadius: 7 }} color="#f8ff96">
				{status}
			</Tag>
		)
	} else if (status === "SUCCESS") {
		return (
			<Tag style={{ borderRadius: 7 }} color="#ebb8ee">
				{status}
			</Tag>
		)
	} else if (status === "FAILED") {
		return (
			<Tag style={{ borderRadius: 7 }} color="#b8eeca">
				{status}
			</Tag>
		)
	} else if (status === "CANCEL") {
		return (
			<Tag style={{ borderRadius: 7 }} color="#ff3d55">
				{status}
			</Tag>
		)
	} else {
		return <Tag style={{ borderRadius: 7 }}>{status}</Tag>
	}
}
