import { Navigate, RouteObject } from "react-router"
import React, { lazy } from "react"

// import AuthPage from "./AuthPage"
// import ForgotPasswordPage from "./ForgotPasswordPage"
import { Paths } from "@shared/constant"

const IndividualLoginPage = lazy(() => import("./IndividualLoginPage"))
const ResellerLoginPage = lazy(() => import("./ResellerLoginPage"))
const ForgotPasswordPage = lazy(() => import("./ForgotPasswordPage"))

export const AuthRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.AuthIndividualLogin} /> },
	{ path: "login", element: <IndividualLoginPage /> },
	{ path: "reseller", element: <ResellerLoginPage /> },
	{ path: "forget-password", element: <ForgotPasswordPage /> },
]
