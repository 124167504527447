import { savePDF } from "@progress/kendo-react-pdf"

class DocService {
	createPdf = (html: any, fileName: any) => {
		savePDF(html, {
			paperSize: "A4",
			fileName: fileName,
			margin: 25,
			scale: 0.8,
		})
	}
}

export const utilsGeneratorPdf = new DocService()
