import { CoreAxiosWithOutInstanceV2 } from "@shared/config"

const END_POINT = "/auth/individual/"

export const AuthIndividualService = {
	login: async (payload: any) => {
		return await CoreAxiosWithOutInstanceV2.post(`${END_POINT}login`, payload)
	},
	register: (payload: any) => {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}register`, payload)
	},
	resetPassword: (payload: any) => {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}resetPassword`, payload)
	},
}
