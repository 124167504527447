import { ILoginCredential, IRegisterCredential } from "@shared/interfaces"

import { CoreAxiosInstanceV2 } from "@shared/config"

const END_POINT = "/auth/individualUserForReseller/"

export const AuthResellerService = {
	login(payload: ILoginCredential): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}login`, payload)
	},
	register(payload: IRegisterCredential) {
		return CoreAxiosInstanceV2.post(`${END_POINT}register`, payload)
	},
}
