import React, { useEffect } from "react"

import { ProtectedRoutes } from "./ProtectedRoutes"
import { PublicRoutes } from "./PublicRoutes"
import { useAuth } from "@modules/auth/hooks/useAuth"
import { useUserMetaDataByDomain } from "@shared/services"

const AppRoutes = () => {
	const userMetaDataByOriginMutation = useUserMetaDataByDomain()

	useEffect(() => {
		userMetaDataByOriginMutation.mutate(window.location.origin)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const { isAuthenticated } = useAuth()
	return isAuthenticated ? <ProtectedRoutes /> : <PublicRoutes />
}

export default AppRoutes
