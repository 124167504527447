import { Navigate, RouteObject } from "react-router"
import React, { lazy } from "react"

import { Paths } from "@shared/constant"

const SmsOverviewPage = lazy(() => import("./SmsOverviewPage"))

export const SmsRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.SmsOverview} /> },
	{ path: "overview", element: <SmsOverviewPage /> },
]
