import axios, { AxiosError } from 'axios'
import { message, notification } from 'antd'

import { ENV } from '../../../ENV'
import { storage } from '@shared/utils'
const headers = {
	'Content-Type': 'application/json',
	'X-Request-ID': 124,
	'X-Country-Code': 'BD',
	'X-Client-Name': 'CRM',
	'X-Client-Version': 123,
	Authorization: `Bearer ${storage.getToken()}`,
}
export const CoreAxiosInstanceV2 = axios.create({
	baseURL: ENV.ENTERPRISE_END_POINT,
	timeout: 60000,
	headers,
})
CoreAxiosInstanceV2.interceptors.request.use(
	(config) => {
		config.headers['Authorization'] = `Bearer ${storage.getToken()}`
		config.headers['X-Request-ID'] = 'CRM-WEB-STAGING'
		config.headers['X-Country-Code'] = 'BD'
		config.headers['X-Client-Name'] = 'CRM-WEB'
		config.headers['X-Client-Version'] = '1.0'
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)
CoreAxiosInstanceV2.interceptors.response.use(
	(response) => {
		let isBlackList =
			ENV.NOTIFICATION_BLACKLIST_END_POINT.filter((x) => {
				return response.config.url.includes(x)
			}).length > 0
		if (response?.data?.success === true && isBlackList === false) {
			switch (response.config.method.toUpperCase()) {
				case 'POST':
					message.success('Created Success')
					return response
				case 'PUT':
					message.success('Updated Success')
					return response
				case 'DELETE':
					message.success('DELETE Success')
					return response
			}
		} else {
			if (typeof response?.data?.error === 'string') {
				notification.error({ message: response?.data?.error })
			}
		}
		return response
	},
	(error: AxiosError) => {
		try {
			if (error?.response?.status === 401) {
				storage.clear()
				window.location.assign(window.location.origin as unknown as string)
			} else if (error?.response?.data?.errorMessage) {
				notification.error({ message: error?.response?.data?.errorMessage })
			}
		} catch (error) {
			return error
		}
	}
)
