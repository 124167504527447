import { CoreAxiosInstanceV2, MutationConfig } from "@shared/config"

import { IBkashRecharge } from "@shared/interfaces"
import { useMutation } from "react-query"
const END_POINT = "/payments/"

export const PaymentServiceV2 = {
	rechargeBkash(payload: IBkashRecharge) {
		return CoreAxiosInstanceV2.post(`${END_POINT}recharge/bkash`, payload)
	},
}
interface BkashRechargeMutation {
	config?: MutationConfig<typeof PaymentServiceV2.rechargeBkash>
}

export const useBkashRechargeMutation = ({ config }: BkashRechargeMutation) => {
	return useMutation({
		...config,
		mutationFn: PaymentServiceV2.rechargeBkash,
	})
}
