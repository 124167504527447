import { UserTypes } from "."

const INITIAL_STATE = {
	currentUserInfo: {},
	userMetaInfo: {},
	selectedUser: "",
}
export const userReducer = (state = INITIAL_STATE, action: any) => {
	const { payload } = action

	switch (action.type) {
		case UserTypes.GET_USER_INFO_SUCCESS:
			return {
				...state,
				currentUserInfo: payload?.data,
			}
		case UserTypes.SELECT_USER:
			return {
				...state,
				selectedUser: payload,
			}
		case UserTypes.GET_USER_META_DATA_SUCCESS:
			if (payload === null) {
				return state
			} else {
				return {
					...state,
					userMetaInfo: payload,
				}
			}
		default:
			return state
	}
}
