import {
	ICostCount,
	ICreateSmsCampaign,
	IFilterSmsCampaigns,
	ISmsCampaignDetails,
} from "@shared/interfaces"
import { MutationConfig, QueryConfig, queryClient } from "@shared/config"
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "./../config/axios/core.axios.v2"
import { concatQuery } from "@shared/utils"

const END_POINT = "/smsCampaigns/"

export const SmsCampaignServiceV2 = {
	NAME: "SmsCampaignService",
	getByUser(options: IFilterSmsCampaigns): any {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}getByUser?${concatQuery(options)}`
		)
	},
	getSmsCampaignDetails(options: ISmsCampaignDetails): any {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}getSmsCampaignDetails?${concatQuery(options)}
			`
		)
	},
	createCampaign(payload: ICreateSmsCampaign | any) {
		return CoreAxiosInstanceV2.post(`${END_POINT}createCampaign`, payload)
	},
	costCount(payload: ICostCount | any): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}costCount`, payload)
	},
	staticJsonDatas(code: string) {
		return CoreAxiosInstanceV2.get(`/staticJsonDatas/${code}`)
	},
	getById(id: string) {
		return CoreAxiosInstanceV2.get(`${END_POINT}${id}`)
	},
}
interface SmsCampaigns {
	config?: QueryConfig<typeof SmsCampaignServiceV2.getByUser>
	options?: IFilterSmsCampaigns
}

export const useSmsCampaigns = ({ config, options }: SmsCampaigns = {}) => {
	const queryName = `${SmsCampaignServiceV2.NAME}${SmsCampaignServiceV2.getByUser.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => SmsCampaignServiceV2.getByUser(options),
	})
}
interface SmsCampaignDetails {
	config?: QueryConfig<typeof SmsCampaignServiceV2.getSmsCampaignDetails>
	options?: ISmsCampaignDetails
}

export const useSmsCampaignDetails = ({
	config,
	options,
}: SmsCampaignDetails = {}) => {
	const queryName = `${SmsCampaignServiceV2.NAME}${SmsCampaignServiceV2.getSmsCampaignDetails.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => SmsCampaignServiceV2.getSmsCampaignDetails(options),
	})
}
interface CreateSmsCampaign {
	config?: MutationConfig<typeof SmsCampaignServiceV2.getByUser>
}

export const useCreateSmsCampaign = ({ config }: CreateSmsCampaign) => {
	return useMutation({
		...config,
		mutationFn: SmsCampaignServiceV2.createCampaign,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${SmsCampaignServiceV2.NAME}${SmsCampaignServiceV2.getByUser.name}`
			)
		},
	})
}
interface SmsCampaignCostCount {
	config?: MutationConfig<typeof SmsCampaignServiceV2.costCount>
}

export const useSmsCampaignCostCount = ({ config }: SmsCampaignCostCount) => {
	return useMutation({
		...config,
		mutationFn: SmsCampaignServiceV2.costCount,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${SmsCampaignServiceV2.NAME}${SmsCampaignServiceV2.costCount.name}`
			)
		},
	})
}

interface SmsCampaignStaticJSONData {
	config?: MutationConfig<typeof SmsCampaignServiceV2.staticJsonDatas>
}

export const useSmsCampaignStaticJSONData = ({
	config,
}: SmsCampaignStaticJSONData) => {
	return useMutation({
		...config,
		mutationFn: SmsCampaignServiceV2.staticJsonDatas,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${SmsCampaignServiceV2.NAME}${SmsCampaignServiceV2.staticJsonDatas.name}`
			)
		},
	})
}
interface SmsCampaign {
	config?: QueryConfig<typeof SmsCampaignServiceV2.getById>
	id?: string
}

export const useSmsCampaign = ({ config, id }: SmsCampaign = {}) => {
	const queryName = `${SmsCampaignServiceV2.NAME}${SmsCampaignServiceV2.getById.name}`
	return useQuery({
		...config,
		queryKey: [queryName, id],
		queryFn: () => SmsCampaignServiceV2.getById(id),
	})
}
