export const SmsCampaignTypes = {
	CLEAN_SMS_CAMPAIGN_REDUCER: 'CLEAN_SMS_CAMPAIGN_REDUCER',
	SMS_CAMPAIGN_INSERT_TYPE: 'SMS_CAMPAIGN_INSERT_TYPE',
	SMS_CAMPAIGN_INSERT_CAMPAIGN_NAME: 'SMS_CAMPAIGN_INSERT_CAMPAIGN_NAME',
	SMS_CAMPAIGN_INSERT_BODY: 'SMS_CAMPAIGN_INSERT_BODY',
	SMS_CAMPAIGN_INSERT_USER: 'SMS_CAMPAIGN_INSERT_USER',
	SMS_CAMPAIGN_INSERT_MASK: 'SMS_CAMPAIGN_INSERT_MASK',
	SMS_CAMPAIGN_INSERT_FILE_HEADER: 'SMS_CAMPAIGN_INSERT_FILE_HEADER',

	SMS_CAMPAIGN_INSERT_RECIPIENT: 'SMS_CAMPAIGN_INSERT_RECIPIENT',

	SMS_CAMPAIGN_INSERT_CONTACTS: 'SMS_CAMPAIGN_INSERT_CONTACTS',
	SMS_CAMPAIGN_INSERT_SEGMENT: 'SMS_CAMPAIGN_INSERT_SEGMENT',
	SMS_CAMPAIGN_INSERT_DATE: 'SMS_CAMPAIGN_INSERT_DATE',
	SMS_CAMPAIGN_INSERT_RECIPIENTS: 'SMS_CAMPAIGN_INSERT_RECIPIENTS',
	SMS_CAMPAIGN_INSERT_FILE: 'SMS_CAMPAIGN_INSERT_FILE',

	SMS_CAMPAIGN_STRATEGY_STEPS_UPDATE: 'SMS_CAMPAIGN_STRATEGY_STEPS_UPDATE',
	SMS_CAMPAIGN_RECIPIENT_STEPS_UPDATE: 'SMS_CAMPAIGN_RECIPIENT_STEPS_UPDATE',

	SMS_CAMPAIGN_GET_COUNT_SUCCESS: 'SMS_CAMPAIGN_GET_COUNT_SUCCESS',

	SMS_CAMPAIGN_CLEAN_UP: 'SMS_CAMPAIGN_CLEAN_UP',
}
