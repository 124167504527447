import { Navigate, RouteObject } from "react-router"
import React, { lazy } from "react"

import { Paths } from "@shared/constant"

const BkashPaymentPage = lazy(() => import("./BkashPaymentPage"))

export const PaymentRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.BkashPayments} /> },
	{ path: "bkash", element: <BkashPaymentPage /> },
]
