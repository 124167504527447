import {
	Authorization,
	DebounceUserSelectField,
	RangeDatePickerOptions,
} from "@shared/components"
/* eslint-disable react-hooks/exhaustive-deps */
import { DatePickerOptions, DeliveryStatus } from "@shared/enums"
import {
	IDashboardSmsOverview,
	IOption,
	ISms,
	ISmsCountOperator,
	ISmsCountStatus,
} from "@shared/interfaces"
import React, { useState } from "react"
import { getDurationUtil, getSessionUtil } from "@shared/utils"
import {
	useGetLiveLogs,
	useSmsCountUsingStatus,
	useSmsDashboardOverview,
	useSmsGroupByOperator,
	useSmsOverviewForToday,
	useTimeSeries,
	useUser,
} from "@shared/services"

import ActionsBoardCard from "../components/ActionsBoardCard"
import ActivityTrackerChart from "../components/ActivityTrackerChart"
import AppLayout from "@theme"
import LiveLogTable from "../components/LiveLogTable"
import MaskingWiseChart from "../components/MaskingWiseChart"
import OperatorChart from "../components/OperatorChart"
import { PageHeader } from "antd"
import StatisticsChart from "../components/StatisticsChart"
import { isEmpty } from "lodash"
import moment from "moment"
import { useNavigate } from "react-router-dom"

const DashboardPage = () => {
	const navigator = useNavigate()
	const loginUser: any = getSessionUtil()
	const [hitLoad, setHitLoad] = useState({
		user: loginUser?.id,
		active: DatePickerOptions.TODAY,
		start: moment().format("YYYY-MM-DD"),
		end: moment().format("YYYY-MM-DD"),
	})
	const { end, start, user, active } = hitLoad
	const dateDifference = moment(end).diff(moment(start), "days")
	const options = {
		user: user,
		startDate: moment(start).format("YYYY-MM-DD"),
		endDate: moment(end).format("YYYY-MM-DD"),
		deliveryStatus: DeliveryStatus.ALL,
		page: 1,
		take: 10,
		duration: getDurationUtil(dateDifference),
	}
	//?
	const smsDashboardOverviewQuery = useSmsDashboardOverview({
		options: {
			user: user,
			startDate: moment(start).format("YYYY-MM-DD"),
			endDate: moment(end).format("YYYY-MM-DD"),
			deliveryStatus: DeliveryStatus.ALL,
			page: 1,
			take: 10,
			duration: getDurationUtil(dateDifference),
		},
		config: {
			keepPreviousData: true,
		},
	})
	const smsGroupByOperatorQuery = useSmsGroupByOperator({
		options,
		config: {
			keepPreviousData: true,
		},
	})
	const smsCountUsingStatusQuery = useSmsCountUsingStatus({
		options,
		config: {
			keepPreviousData: true,
		},
	})
	const timeSeriesQuery = useTimeSeries({
		options: {
			user: user,
			startDate: moment(start).format("YYYY-MM-DD"),
			endDate: moment(end).format("YYYY-MM-DD"),
			duration: getDurationUtil(dateDifference),
			page: 1,
			take: 1000,
		},
		config: {
			enabled: active !== DatePickerOptions.TODAY,
			keepPreviousData: true,
		},
	})
	const smsOverviewForTodayQuery = useSmsOverviewForToday({
		options,
		config: {
			enabled: active === DatePickerOptions.TODAY,
			keepPreviousData: true,
		},
	})
	const [liveLogOptions, setLiveLogOptions] = useState({
		page: 1,
		take: 0,
		total: 0,
		searchTerm: "",
	})
	const getLiveLogsQuery = useGetLiveLogs({
		options: {
			user: hitLoad.user,
			searchTerm: liveLogOptions.searchTerm,
			page: liveLogOptions.page,
			take: liveLogOptions.take,
			startDate: moment(start).format("YYYY-MM-DD"),
			endDate: moment(end).format("YYYY-MM-DD"),
		},
		config: {
			keepPreviousData: false,
		},
	})
	const userQuery = useUser({ user: hitLoad.user })
	const currentUserName = !isEmpty(userQuery?.data?.data?.data?.userInfo?.name)
		? String(userQuery?.data?.data?.data.userInfo?.name)
		: ""
	const totalSmsCount: number =
		smsDashboardOverviewQuery?.data?.data?.data?.reduce(
			(acc: number, curr: IDashboardSmsOverview) =>
				acc + parseInt(String(curr.totalSmsCount)),
			0
		)
	const totalSmsCost: number =
		smsDashboardOverviewQuery?.data?.data?.data?.reduce(
			(acc: number, curr: IDashboardSmsOverview) =>
				acc + parseInt(String(curr.totalSmsCost)),
			0
		)
	const smsStatusCount: number[] =
		smsCountUsingStatusQuery?.data?.data?.data?.map((status: ISmsCountStatus) =>
			parseInt(status.count)
		)
	const smsStatusLabels: string[] =
		smsCountUsingStatusQuery?.data?.data?.data?.map((status: ISmsCountStatus) =>
			String(status.deliveryStatus)
		)
	//Operator
	const smsOperatorLabels: string[] =
		smsGroupByOperatorQuery?.data?.data?.data.map(
			(x: ISmsCountOperator) => x?.sms_operator
		)
	const smsOperatorCounts: number[] =
		smsGroupByOperatorQuery?.data?.data?.data.map((x: ISmsCountOperator) =>
			parseInt(x.count)
		)
	//Masking
	const maskWiseLabels: string[] =
		smsDashboardOverviewQuery?.data?.data?.data.map(
			(maskItem: IDashboardSmsOverview) => maskItem?.mask
		)
	const maskWiseCounts: number[] =
		smsDashboardOverviewQuery?.data?.data?.data.map(
			(maskItem: IDashboardSmsOverview) => maskItem?.totalSmsCount
		)
	const timSeriesData =
		active === DatePickerOptions.TODAY
			? smsOverviewForTodayQuery?.data?.data?.data
			: timeSeriesQuery?.data?.data?.data
	return (
		<AppLayout pageTitle="Dashboard">
			<PageHeader
				title="SMS Dashboard"
				subTitle="Activity log's"
				extra={[
					<div className="flex items-center space-x-2">
						<RangeDatePickerOptions
							styles={{ marginBottom: 15 }}
							onChangeStartEndDate={(range: any) => {
								setHitLoad({
									...hitLoad,
									start: moment(range.start).format("YYYY-MM-DD"),
									end: moment(range.end).format("YYYY-MM-DD"),
									active: range?.active,
								})
							}}
						/>
						<Authorization requireRoles={["RESELLER"]}>
							<div style={{ minWidth: 270 }}>
								<DebounceUserSelectField
									onChange={(val: IOption) => {
										setHitLoad({
											...hitLoad,
											user: val?.value?.id,
										})
									}}
								/>
							</div>
						</Authorization>
					</div>,
				]}
			/>
			<div className="col-span-3 p-0 rounded-tl-3xl">
				<div className="block lg:grid grid-cols-4 gap-5 space-y-5">
					<StatisticsChart
						loading={smsCountUsingStatusQuery.isLoading as boolean}
						labels={smsStatusLabels}
						count={smsStatusCount}
					/>
					<OperatorChart
						labels={smsOperatorLabels}
						count={smsOperatorCounts}
						loading={smsGroupByOperatorQuery.isLoading as boolean}
					/>
					<MaskingWiseChart
						labels={maskWiseLabels}
						count={maskWiseCounts}
						loading={smsDashboardOverviewQuery.isLoading as boolean}
					/>
					<OperatorChart
						labels={smsOperatorLabels}
						count={smsOperatorCounts}
						loading={smsGroupByOperatorQuery.isLoading as boolean}
					/>
				</div>
			</div>
			<div className="block lg:grid grid-cols-8 gap-5 py-5">
				<div className="col-span-3">
					<ActionsBoardCard title={`Welcome, ${currentUserName}`} />
				</div>
				<div className="col-span-5">
					<ActivityTrackerChart
						totalSmsCount={totalSmsCount}
						totalSmsCost={totalSmsCost}
						failed={timSeriesData?.failed}
						pending={timSeriesData?.pending}
						success={timSeriesData?.success}
						times={timSeriesData?.times}
						loading={
							(timeSeriesQuery.isLoading ||
								smsOverviewForTodayQuery.isLoading) as boolean
						}
					/>
				</div>
			</div>
			<LiveLogTable
				smsItems={getLiveLogsQuery?.data?.data?.data as ISms[]}
				loading={getLiveLogsQuery.isLoading as boolean}
				pagination={{
					current: getLiveLogsQuery?.data?.data?.page,
					page: getLiveLogsQuery?.data?.data?.page,
					take: getLiveLogsQuery?.data?.data?.take,
					total: getLiveLogsQuery?.data?.data?.total,
				}}
				onTableChange={(pagination, filter, sorter) => {
					setLiveLogOptions({
						...liveLogOptions,
						page: pagination.current,
						take: pagination.pageSize,
					})
				}}
				onSearch={(term: string) => {
					setLiveLogOptions({
						...liveLogOptions,
						page: liveLogOptions.page,
						take: 7,
						searchTerm: term,
					})
				}}
			/>
		</AppLayout>
	)
}
export default DashboardPage
