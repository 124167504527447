import { Navigate, Outlet, useRoutes } from 'react-router'
import { useInterval, useTitle } from 'ahooks'

import { AutomatonsRoutes } from '@modules/automation/routes'
import { BillingRoutes } from '@modules/billing'
import { CampaignRoutes } from '@modules/campaign/routes'
import { CommonRoutes } from '@modules/common/routes'
import { ContactRoutes } from '@modules/contact/routes'
import DashboardPage from '@modules/dashboard/routes/DashboardPage'
import { DashboardRoutes } from '@modules/dashboard/routes'
import { ENV } from '@ENV'
import { InstantSmsRoutes } from '@modules/instant-sms/routes'
import { MaskingRoutes } from '@modules/mask/routes'
import { Paths } from '@shared/constant'
import { PaymentRoutes } from '@modules/payment/routes'
import React from 'react'
import { ReportsRoutes } from '@modules/report/routes'
import { SmsRoutes } from '@modules/sms/routes'
import { TemplatesRoutes } from '@modules/templates/routes'
import { UsersRoutes } from '@modules/user/routes'
import { storage } from '@shared/utils'
import { useAuth } from '@modules/auth'

const App = () => {
	useTitle(String('SMS Panel'))
	const { refreshTokenFn } = useAuth()
	const token = storage.getToken()
	useInterval(() => {
		if (token) {
			refreshTokenFn.mutateAsync(token)
		}
	}, ENV.REFRESH_TOKEN_EXPIRE_TIME)
	let pathName = window.location.pathname
	return (
		<>
			{pathName === '/' ? <DashboardPage /> : ''}
			<Outlet />
		</>
	)
}

export const ProtectedRoutes = () => {
	const routes = [
		{
			path: 'billings',
			children: BillingRoutes,
		},
		{
			path: 'automatons',
			children: AutomatonsRoutes,
		},
		{
			path: 'campaign',
			children: CampaignRoutes,
		},
		{
			path: 'common',
			children: CommonRoutes,
		},
		{
			path: 'contact',
			children: ContactRoutes,
		},
		{
			path: 'dashboard',
			children: DashboardRoutes,
		},
		{
			path: 'instant',
			children: InstantSmsRoutes,
		},
		{
			path: 'maskings',
			children: MaskingRoutes,
		},
		{
			path: 'payments',
			children: PaymentRoutes,
		},
		{
			path: 'reports',
			children: ReportsRoutes,
		},
		{
			path: 'sms',
			children: SmsRoutes,
		},
		{
			path: 'templates',
			children: TemplatesRoutes,
		},
		{
			path: Paths.Users,
			children: UsersRoutes,
		},
	]

	return useRoutes([
		{
			path: '',
			element: <App />,
			children: routes,
		},
		{
			path: '*',
			element: <Navigate to={'/'} />,
		},
	])
}
