import { storage } from "@shared/utils"
type UserT = "RESELLER" | "INDIVIDUAL"
interface Props {
	requireRoles: UserT[]
	children: any
}
export const Authorization: React.FC<Props> = ({ requireRoles, children }) => {
	const userRole: any = storage.getRole()
	const userHasRequireRole: boolean = requireRoles.includes(userRole)
	return userHasRequireRole && children
}
