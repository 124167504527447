// import '@shared/styles/app.scss'

import * as serviceWorker from './serviceWorker'

import App from './App'
import ReactDOM from 'react-dom'
import { withClearCache } from '@shared/hooks'

const ClearCacheComponent = withClearCache(App)

ReactDOM.render(<ClearCacheComponent />, document.getElementById('root'))
serviceWorker.unregister()
