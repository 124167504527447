import React, { CSSProperties } from 'react'

import { Input } from 'antd'
import { useDebounceFn } from 'ahooks'

const { Search } = Input

interface Props {
	placeholder?: string
	onChange?: (term: string) => void
	style?: CSSProperties
}

export const DebounceSearchInputField: React.FC<Props> = ({
	onChange,
	style,
}) => {
	const { run } = useDebounceFn(
		(e: any) => {
			onChange(e)
		},
		{
			wait: 500,
		}
	)
	return (
		<Search
			placeholder='Search '
			allowClear
			enterButton
			size='middle'
			style={style}
			onChange={(e: any) => {
				run(e.target.value)
			}}
		/>
	)
}
