import * as Sentry from '@sentry/react'

import { applyMiddleware, compose, createStore } from 'redux'

import logger from 'redux-logger'
import { persistStore } from 'redux-persist'
import rootReducer from './sourceReducer'
import thunk from 'redux-thunk'

const middleware = [thunk]
if (process.env.NODE_ENV === 'development') {
	middleware.push(logger as any)
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	// Optionally pass options
})
export const store: any = createStore(
	rootReducer,
	compose(applyMiddleware(...middleware), sentryReduxEnhancer)
)

export const persistor = persistStore(store)
