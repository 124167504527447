import React from "react"

interface Props {
	title?: string
	note?: string
	icon?: any
	onClick?: any
	className?: string
	border?: boolean
	iconClick?: () => void
}

export const CommonTitle: React.FC<Props> = ({
	title,
	note,
	icon,
	onClick,
	className,
	border,
	iconClick,
}) => {
	return (
		<div
			className={`common-title ${className ? className : ""} ${
				border ? "border-bottom-primary" : ""
			} `}
			onClick={onClick}>
			<div className="common-title-inner">
				{icon ? (
					<div className="common-title-icon" onClick={iconClick}>
						{icon ? icon : ""}
					</div>
				) : (
					""
				)}
				<h2>{title}</h2>
			</div>
			{note ? <p>{note}</p> : ""}
		</div>
	)
}
