export enum AccountType {
	PostPaid = "POSTPAID",
	Prepaid = "PREPAID",
}
export enum Status {
	SUCCESS = "SUCCESS",
	PENDING = "PENDING",
	FAILED = "FAILED",
}
export enum DeliveryStatus {
	SUCCESS = "SUCCESS",
	PENDING = "PENDING",
	ALL = "ALL",
	FAILED = "FAILED",
}
export enum DatePickerOptions {
	TODAY = "Today",
	LAST7DAY = "Last 7 Days",
	LAST15DAY = "LAST15DAY",
	THIS_MONTH = "This Month",
	START_DAY = "Start Date",
	END_DAY = "End Date",
	RANGE = "range",
	YESTERDAY = "YESTERDAY",
	LAST_MONTH = "LAST_MONTH",
}
export enum MaskingTypes {
	all = "ALL",
	masking = "MASKING",
	nonMasking = "NONMASKING",
}
export enum ToggleStatus {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
}

export enum TimeFormat {
	secondary = "DD-MM-YYYY hh:mm:ss A",
	primary = "DD-MM-YYYY",
	PAYLOAD = "YYYY-MM-DD",
	YYYYMMDD = "YYYY-MM-DD",
}
export enum UserType {
	RESELLER = "RESELLER",
	INDIVIDUAL = "INDIVIDUAL",
}

export enum MaskStatus {
	ALL = "ALL",
	PENDING = "PENDING",
	COMPLETED = "COMPLETED",
}
