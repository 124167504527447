import { AccountType, TimeFormat, ToggleStatus } from "@shared/enums"
import { currencySign, regex, smsCharacterCount } from "@shared/constant"

import { IBaseFilterQuery } from "@shared/interfaces"
import { IUserMetaData } from "./../interfaces/user.interfaces"
import jwt_decode from "jwt-decode"
import moment from "moment"

export const commonGridGutters = { xs: 5, sm: 10, md: 10, xl: 12, xxl: 15 }
export const getDurationUtil = (day: number) => {
	if (day < 7) {
		return 1
	} else if (day < 29) {
		return 1
	} else {
		return 30
	}
}
export const smsEncodedUtil = (x: string) => {
	const text: string = String(x)
	try {
		return decodeURIComponent(text).length > 0 ? decodeURIComponent(text) : text
	} catch (err) {
		return text
	}
}
export function filterQueryUtil(option: IBaseFilterQuery): string {
	return `${option.page ? `page=${option?.page}` : ""}${
		option.take ? `&take=${option?.take}` : ""
	}${option.searchTerm ? `&searchTerm=${option?.searchTerm}` : ""}`
}
export const isNonLatinBodyUtil = (s: string) => {
	// eslint-disable-next-line
	return /[^\u0000-\u007F]/.test(s)
}
export const genRandomNameUtil = (str: string) => {
	const date = timeFormatUtil(String(new Date()), TimeFormat.primary)
		.split("-")
		.join("")
	const mask = String(str).slice(0, 30).toUpperCase()
	const randomId = String(new Date().getTime()).slice(0, 3)
	return `${date}-${mask}-${randomId}`
}
export const textTrimUtil = (string: string) => {
	return string?.replace(/\s/g, "")
}
export const responsiveBreakPoint = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1650,
}
type TimeFormatType = "DD-MM-YYYY hh:mm:ss A" | "DD-MM-YYYY" | "YYYY-MM-DD"
export const timeFormatUtil = (
	time: string,
	format: TimeFormat | TimeFormatType
) => {
	return moment(time).format(format)
}
export const getSessionUtil = (): { id: string } | any => {
	try {
		const token: any = storage.getToken()
		const decoded: any = jwt_decode(token)
		if (decoded) {
			return decoded
		} else {
			return false
		}
	} catch (error) {
		return false
	}
}
export const boolToToggleUtil = (status: boolean) => {
	return status === true ? ToggleStatus.ACTIVE : ToggleStatus.INACTIVE
}
export const SMSCostCalculateUtil = (body: string, rate: number): number => {
	return parseFloat(
		(Math.ceil(body.length / smsCharacterCount) * rate).toFixed(2)
	)
}
export const userBalanceUtil = (
	accountType: AccountType,
	balance: number,
	dueBalance: number
) => {
	if (accountType === AccountType.PostPaid) {
		return `${currencySign}${Number(dueBalance).toFixed(2)}`
	} else if (accountType === AccountType.Prepaid) {
		return `${currencySign}${Number(balance).toFixed(2)}`
	} else {
		return 0
	}
}
export const phoneNumberValidateUtil = (phoneNumber: any) => {
	try {
		let validNumber: any
		const number = phoneNumber.match(regex.BdPhone)
		if (number) {
			// eslint-disable-next-line
			number.map((number: any) => {
				validNumber = `0${number.slice(number.length - 10, number.length)}`
			})
			return validNumber
		} else {
			return false
		}
	} catch (error) {
		return false
	}
}
export const toggleToBoolUtil = (status: string) => {
	return status === ToggleStatus.ACTIVE ? true : false
}
const storagePrefix = "547h1"
export const storage = {
	getToken: (): string | false => {
		if (!window.localStorage || !window.JSON) {
			return false
		}
		const item = localStorage.getItem(`${storagePrefix}TOKEN`)
		if (!item) {
			return false
		}
		return JSON.parse(item) as string
	},
	getRole: (): string | false => {
		if (!window.localStorage || !window.JSON) {
			return false
		}
		var item = localStorage.getItem(`${storagePrefix}ROLE`)
		if (!item) {
			return false
		}
		return JSON.parse(item) as string
	},
	getMeta: (): IUserMetaData => {
		if (!window.localStorage || !window.JSON) {
			return
		}
		var item = localStorage.getItem(`${storagePrefix}META_DATA`)
		if (!item) {
			return
		}
		return JSON.parse(item) as IUserMetaData
	},
	setToken: (token: string) => {
		if (token) {
			window.localStorage.setItem(
				`${storagePrefix}TOKEN`,
				JSON.stringify(token)
			)
		}
	},
	setMeta: (meta: {}) => {
		if (meta) {
			window.localStorage.setItem(
				`${storagePrefix}META_DATA`,
				JSON.stringify(meta)
			)
		}
	},
	setRole: (role: string) => {
		if (role) {
			window.localStorage.setItem(`${storagePrefix}ROLE`, JSON.stringify(role))
		}
	},
	clear: () => {
		localStorage.clear()
	},
	setData(key: string, data: any) {
		if (!window.localStorage || !window.JSON || !key) {
			return
		}
		localStorage.setItem(key, JSON.stringify(data))
	},
	getDate(key: string) {
		const item = JSON.stringify(localStorage.getItem(key))
		if (!item) {
			return false
		}
		return JSON.parse(item)
	},
	removeData(key: string) {
		if (!window.localStorage || !window.JSON || !key) {
			return
		}
		localStorage.removeItem(key)
	},
}
export const concatQuery = (options: any): string => {
	const items = {}
	Object.keys(options).map((x) => {
		if (options[x] === `[""]`) return
		if (Boolean(options[x])) {
			items[x] = options[x]
		}
	})
	return Object.keys(items)
		.map((x) => {
			return items[x] && `${x}=${items[x]}`
		})
		.join("&")
}

// clean array from empty values
export const cleanArray = (array: any[]) => {
	return array.filter((item: any) => {
		return item !== ""
	})
}

// clean payload from empty values
export const cleanPayload = (payload: any) => {
	const newPayload = {}
	Object.keys(payload).map((key: string) => {
		if (payload[key] !== "") {
			newPayload[key] = payload[key]
		}

		// if
	})
	return newPayload
}

export const methodSuccessMessage = (
	method: "POST" | "PUT" | "DELETE" | string
): string => {
	switch (method.toUpperCase()) {
		case "POST":
			return "Created Success"
		case "PUT":
			return "Update Success"
		case "DELETE":
			return "Delete Success"
		default:
			return ""
	}
}

// is empty
export const isEmpty = (value: any) => {
	// check empty array
	if (Array.isArray(value)) {
		return value.length === 0
	}
	// check empty object
	if (typeof value === "object") {
		return Object.keys(value).length === 0
	}
	return value === undefined || value === null || value === ""
}
