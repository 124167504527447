export const HOME_PAGE_TITLE = `User Panel`
export const USER_SIGN_IN_TITLE = `Sing In`
export const USER_SIGN_UP_TITLE = `Sign Up`
export const RESELLER_SIGN_UP_TITLE = `Reseller Sign Up`
export const RESELLER_SIGN_IN_TITLE = `Reseller Sign In`
export const CONTACT_TITLE = `Contact`
export const DASHBOARD_TITLE = `Dashboard`
export const SMS_OVERVIEW_TITLE = `Overview`
export const CREATE_CAMPAIGN_TITLE = `Create Campaign`
export const INSTANT_SMS_TITLE = `Instant SMS`
export const TEMPLATE_TITLE = `Templates`
export const CAMPAIGN_OVERVIEW_TITLE = `Campaign Overview`
export const DOCS_TITLE = `Docs`
export const PROFILE_TITLE = `Profile`
export const REPORT_TITLE = `Reports`
export const TERMS_AND_CONDITION_TITLE = `Terms and Conditions`
export const MASKING_REQUEST_TITLE = `Masking Request`
export const RESELLER_REQUEST = `Reseller Request`
export const ANALYTICS_TITLE = `Analytics`
export const AUTOMATONS_TITLE = `Automatons`
export const USERS_TITLE = `Users`
export const COMING_SOON_TITLE = `Coming Soon`
export const UNAUTHORIZED_TITLE = `Unauthorized`
export const FORGOT_PASSWORD_TITLE = `Forgot Password`
