import React, { lazy } from 'react'

import { Navigate } from 'react-router'
import { Paths } from '@shared/constant'
import TestPage from './TestPage'

const ApiDocsPage = lazy(() => import('./ApiDocsPage'))
const ComingSoonPage = lazy(() => import('./ComingSoonPage'))
const HelpAndSupportPage = lazy(() => import('./HelpAndSupportPage'))
const TermsAndConditionPage = lazy(() => import('./TermsAndConditionPage'))

export const CommonRoutes = [
	{ path: '', element: <Navigate to={Paths.ApiDocs} /> },
	{ path: 'api-docs', element: <ApiDocsPage /> },
	{ path: 'up-coming', element: <ComingSoonPage /> },
	{ path: 'help', element: <HelpAndSupportPage /> },
	{ path: 'policy', element: <TermsAndConditionPage /> },
	{ path: 'test', element: <TestPage /> },
]
