import React, { useEffect, useState } from "react"

import moment from "moment"
import packageJson from "../../../package.json"

const buildDateGreaterThan = (latestDate: any, currentDate: any) => {
	const momLatestDateTime = moment(latestDate)
	const momCurrentDateTime = moment(currentDate)

	if (momLatestDateTime.isAfter(momCurrentDateTime)) {
		return true
	} else {
		return false
	}
}

export function withClearCache(Component: any) {
	function ClearCacheComponent(props: any) {
		const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)

		useEffect(() => {
			fetch("/meta.json")
				.then((response) => response.json())
				.then((meta) => {
					const latestVersionDate = meta.buildDate
					const currentVersionDate = packageJson.buildDate

					const shouldForceRefresh = buildDateGreaterThan(
						latestVersionDate,
						currentVersionDate
					)
					if (shouldForceRefresh) {
						setIsLatestBuildDate(false)
						refreshCacheAndReload()
					} else {
						setIsLatestBuildDate(true)
					}
				})
		}, [])

		const refreshCacheAndReload = () => {
			if (caches) {
				// Service worker cache should be cleared with caches.delete()
				caches.keys().then((names: any) => {
					for (const name of names) {
						caches.delete(name)
					}
				})
			}
			// delete browser cache and hard reload
			//@ts-ignore
			window.location.reload(true)
		}

		return (
			<React.Fragment>
				{isLatestBuildDate ? <Component {...props} /> : null}
			</React.Fragment>
		)
	}

	return ClearCacheComponent
}
