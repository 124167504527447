import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import { ICreateMaskRequest, IFilterRequestedMask } from "@shared/interfaces"
import { useMutation, useQuery } from "react-query"

import { concatQuery } from "./../utils/util-function"

const END_POINT = "/maskingRequests/"

export const MaskingRequestServiceV2 = {
	NAME: "MaskingRequestService",
	filter(options: IFilterRequestedMask): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}?${concatQuery(options)}`)
	},
	create(payload: ICreateMaskRequest): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}`, payload)
	},
}

interface CreateMaskRequest {
	config?: MutationConfig<typeof MaskingRequestServiceV2.create>
}

export const useCreateMaskRequest = ({ config }: CreateMaskRequest) => {
	return useMutation({
		...config,
		mutationFn: MaskingRequestServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${MaskingRequestServiceV2.NAME}${MaskingRequestServiceV2.filter.name}`
			)
		},
	})
}
interface MaskRequests {
	config?: QueryConfig<typeof MaskingRequestServiceV2.filter>
	options?: IFilterRequestedMask
}

export const useMaskRequests = ({ config, options }: MaskRequests = {}) => {
	const queryName = `${MaskingRequestServiceV2.NAME}${MaskingRequestServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [queryName, options],
		queryFn: () => MaskingRequestServiceV2.filter(options),
	})
}
