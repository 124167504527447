import { createSelector } from "reselect"

//!STATE
const selectState = (state: any) => state.smsCampaignState
//! STEPPER

export const selectSmsCampaignStepper = createSelector(
	[selectState],
	(state: any) => ({
		viewPoint: state.stepper.strategy,
		campaignType: state.stepper.recipient,
	})
)
//! LOADING
export const selectSmsCampaignLoading = createSelector(
	[selectState],
	(edge: any) => edge.loading
)

export const selectSmsCampaignCreate = createSelector(
	[selectState],
	(state: any) => ({
		campaignCreateType: state?.create?.campaignCreateType,
		conatctGroups: state?.create?.conatctGroups,
		recipients: state?.create?.recipients,
		recipient: state?.create?.recipient,
		contacts: state?.create?.contacts,
		smsMask: state?.create?.smsMask,
		body: state?.create?.body,
		smsCampaignName: state?.create?.smsCampaignName,
		smsCampaignScheduledDate: state?.create?.smsCampaignScheduledDate,
		smsCampaignScheduledTime: state?.create?.smsCampaignScheduledTime,
		smsCampaignStatus: state?.create?.smsCampaignStatus,
		user: state?.create?.user,
		file: state?.create?.file,
		headers: state?.create?.headers,
	})
)
export const selectIsRecipientSelected = createSelector(
	[selectSmsCampaignCreate],
	(create: any) => {
		return create?.conatctGroups?.length ||
			create?.file ||
			create?.recipients?.length ||
			create?.contacts?.length ||
			create?.recipient
			? true
			: false
	}
)
//!OVERVIEW
export const selectSmsCampaignOverview = createSelector(
	[selectState],
	(state: any) => ({
		campaigns: state?.overview?.campaigns,
		pagination: {
			page: state?.overview?.page,
			take: state?.overview?.take,
			total: state?.overview?.total,
		},
	})
)

//! COUNT
export const selectSmsCampaignCostCount = createSelector(
	[selectState],
	(state: any) => ({
		loading: state?.costCount?.loading,
		balance: state?.costCount?.balance,
		duplicateContacts: state?.costCount?.duplicateContacts,
		maskRate: state?.costCount?.maskRate,
		nonMaskRate: state?.costCount?.nonMaskRate,
		totalContacts: state?.costCount?.totalContacts,
		totalMaskCost: state?.costCount?.totalMaskCost,
		totalNonMaskCost: state?.costCount?.totalNonMaskCost,
		uniqueContacts: state?.costCount?.uniqueContacts,
	})
)

//RECIPIENT SUM
export const selectSmsCampaignRecipientSum = createSelector(
	[selectSmsCampaignCreate],
	(state: any) => {
		return state?.recipient?.length
			? state?.recipients?.length + 1
			: state?.recipients?.length
	}
)

//! DETAILS
export const selectSmsCampaignItemDetails = createSelector(
	[selectState],
	(state: any) => ({
		loading: state?.details?.loading,
		smsEntries: state?.details?.smsEntries,
		statusCounts: state?.details?.statusCounts,
		pagination: {
			page: state?.details?.pagination?.page,
			take: state?.details?.pagination?.take,
			total: state?.details?.pagination?.total,
		},
	})
)
