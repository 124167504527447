import * as Sentry from '@sentry/react'

import { persistor, queryClient, store } from '@shared/config'

import { BrowserRouter } from 'react-router-dom'
import { Button } from 'antd'
import { ENV } from '@ENV'
import { ErrorBoundary } from 'react-error-boundary'
import { Integrations } from '@sentry/tracing'
import { LottiePlayer } from '@shared/components'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import React from 'react'
import { SOMETHING_WENT_WRONG } from '@shared/assets'

const ErrorFallback = () => {
	return (
		<div>
			<LottiePlayer data={SOMETHING_WENT_WRONG} />
			<Button
				type='primary'
				className='mt-4'
				onClick={() => window.location.assign(window.location.origin)}>
				Refresh
			</Button>
		</div>
	)
}
type AppProviderProps = {
	children: React.ReactNode
}
export const AppProvider = ({ children }: AppProviderProps) => {
	Sentry.init({
		dsn: ENV.SENTRY_DNS,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	})

	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<React.Suspense
				fallback={
					<div className='flex justify-center items-center h-full w-full'>
						<h1>Loading...</h1>
					</div>
				}>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<PersistGate persistor={persistor}>
							<BrowserRouter>{children}</BrowserRouter>
						</PersistGate>
					</Provider>
				</QueryClientProvider>
			</React.Suspense>
		</ErrorBoundary>
	)
}
