import React, { ReactNode } from "react"

import WinterLayout from "./winter"

interface IFProps {
	children: ReactNode
	pageTitle?: string
}
const AppLayout: React.FC<IFProps> = ({ children, pageTitle }) => {
	return <WinterLayout pageTitle={pageTitle}>{children}</WinterLayout>
}
export default AppLayout
