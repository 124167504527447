import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import { IFilterApiKeys, IGenerateApiKey } from "@shared/interfaces"
import { useMutation, useQuery } from "react-query"

import { concatQuery } from "@shared/utils"

const END_POINT = "/userApiKeys/"

export const UserApiKeyServiceV2 = {
	NAME: "UserApiKeyService",
	create(payload: IGenerateApiKey): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}createApiKey`, payload)
	},
	filter(options: IFilterApiKeys): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}?${concatQuery(options)}`)
	},
}

export const useCreateApiKey = (
	config: MutationConfig<typeof UserApiKeyServiceV2.create>
) => {
	const invalidQuery = `${UserApiKeyServiceV2.NAME}${UserApiKeyServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: UserApiKeyServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(invalidQuery)
		},
	})
}

export const useAPIKey = ({
	config,
	options,
}: {
	config?: QueryConfig<typeof UserApiKeyServiceV2.filter>
	options?: IFilterApiKeys
}) => {
	const queryName = `${UserApiKeyServiceV2.NAME}${UserApiKeyServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => UserApiKeyServiceV2.filter(options),
	})
}
