import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import { getSessionUtil, storage } from "@shared/utils"
import { useMutation, useQuery } from "react-query"

import { ICreateUserMetaData } from "@shared/interfaces"

const END_POINT = `/userMetaDatas/`

export const UserMetaDataServiceV2 = {
	NAME: "UserMetaDataService",
	create(payload: ICreateUserMetaData): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}`, payload)
	},
	get(id: string): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}?user=${id}`)
	},
	findByUser(userId: string): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}findByUser/${userId}`)
	},
	findByDomain(domainName: string): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}findByDomain`, { domainName })
	},
}

export const useCreateUserMetaData = (
	config: MutationConfig<typeof UserMetaDataServiceV2.create>
) => {
	const invalidQuery = `${UserMetaDataServiceV2.NAME}${UserMetaDataServiceV2.findByUser.name}`
	return useMutation({
		...config,
		mutationFn: UserMetaDataServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(invalidQuery)
		},
	})
}

export const useUserMetaDataByUser = ({
	config,
	userId,
}: {
	config?: QueryConfig<typeof UserMetaDataServiceV2.findByUser>
	userId: string
}) => {
	const queryName = `${UserMetaDataServiceV2.NAME}${UserMetaDataServiceV2.findByUser.name}`
	return useQuery({
		...config,
		queryKey: [queryName, userId],
		queryFn: () => UserMetaDataServiceV2.findByUser(userId),
	})
}
interface UserMetaData {
	config?: QueryConfig<typeof UserMetaDataServiceV2.get>
	user?: string
}

export const useUserMetaData = ({ config, user }: UserMetaData = {}) => {
	const KEYS = `${UserMetaDataServiceV2.NAME}${UserMetaDataServiceV2.get.name}`
	const tokenUser = getSessionUtil()
	const id = user || tokenUser.id
	return useQuery({
		...config,
		queryKey: [KEYS, id],
		queryFn: () => UserMetaDataServiceV2.get(id),
	})
}
interface MetaDataByDomain {
	config?: MutationConfig<typeof UserMetaDataServiceV2.findByDomain>
}

export const useUserMetaDataByDomain = ({ config }: MetaDataByDomain = {}) => {
	return useMutation({
		...config,
		mutationFn: UserMetaDataServiceV2.findByDomain,
		onSuccess: (val) => {
			storage.setMeta(val?.data?.data)
		},
	})
}
