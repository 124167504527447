import { AiOutlineMessage, AiOutlineNumber } from "react-icons/ai"
import { Card, Col, Row, Statistic, Typography } from "antd"

import ApexCharts from "react-apexcharts"
import { BiMoney } from "react-icons/bi"
import { Purify } from "@shared/components"
import React from "react"

const { Title } = Typography

interface IFProps {
	totalSmsCount?: number
	totalSmsBodyCount?: number
	totalSmsCost?: number
	times?: string[] | null
	failed?: number[] | null
	success?: number[] | null
	pending?: number[] | null
	loading?: boolean
}
const ActivityTrackerChart: React.FC<IFProps> = ({
	totalSmsCount,
	totalSmsCost,
	times,
	failed,
	success,
	pending,
	loading,
}) => {
	const options = {
		noData: {
			text: "Loading...",
		},
		colors: ["#0089ff", "#EC0944"],
		legend: {
			show: true,
			position: "top",
			horizontalAlign: "right",
			offsetX: 70,
			offsetY: 0,
		},
		tooltip: {
			x: {
				format: "MM",
			},
		},
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.5,
				stops: [0, 80, 100],
			},
		},
		chart: {
			height: 350,
			type: "line",

			toolbar: {
				show: false,
				offsetX: 0,
				tools: {
					download: false,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			type: "category",
			low: 0,
			offsetX: 0,
			offsetY: 0,
			show: true,
			categories: times,
			labels: {
				low: 0,
				offsetX: 0,
				show: true,
			},
			axisBorder: {
				low: 0,
				offsetX: 0,
				show: true,
			},
			axisTicks: {
				show: true,
			},
		},
		markers: {
			strokeWidth: 3,
			colors: "#ffffff",
			strokeColors: ["#0089ff", "#EC0944"],
			hover: {
				size: 6,
			},
		},
		yaxis: {
			low: 0,
			offsetX: 0,
			offsetY: 0,
			show: true,
			labels: {
				low: 0,
				offsetX: 0,
				show: true,
			},
			axisBorder: {
				low: 0,
				offsetX: 0,
				show: true,
			},
		},
		grid: {
			show: false,
			padding: {
				left: 0,
				right: 0,
				bottom: -10,
				top: -40,
			},
		},
	}

	return (
		<Card title="Activity Chart" style={{ minHeight: 500 }}>
			<Purify empty={false} loading={loading}>
				<ApexCharts
					options={options as any}
					series={[
						{
							name: "Success",
							data: success,
						},
						{
							name: "Pending",
							data: pending,
						},
					]}
					type="area"
					height={280}
				/>

				<Row gutter={15}>
					<Col xxl={8} lg={8} md={8} sm={24} xs={24}>
						<Statistic
							valueStyle={{ fontSize: 18 }}
							title={
								<Title level={5} style={{ fontSize: 14 }}>
									Total SMS Count
								</Title>
							}
							value={totalSmsCount ? totalSmsCount : 0}
							prefix={
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: 35,
										width: 35,
										borderRadius: 35,
										fontSize: 16,
										color: "#ffff",
										backgroundColor: "#0089ff",
										marginRight: 15,
									}}>
									<AiOutlineNumber />
								</div>
							}
						/>
					</Col>
					<Col xxl={8} lg={8} md={8} sm={24} xs={24}>
						<Statistic
							valueStyle={{ fontSize: 18 }}
							title={
								<Title level={5} style={{ fontSize: 14 }}>
									Total SMS Body Count
								</Title>
							}
							value={totalSmsCount ? totalSmsCount : 0}
							prefix={
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: 35,
										width: 35,
										borderRadius: 35,
										fontSize: 16,
										color: "#ffff",
										backgroundColor: "#f73164",
										marginRight: 15,
									}}>
									<AiOutlineMessage />
								</div>
							}
						/>
					</Col>
					<Col xxl={8} lg={8} md={8} sm={24} xs={24}>
						<Statistic
							valueStyle={{ fontSize: 18 }}
							title={
								<Title level={5} style={{ fontSize: 14 }}>
									Total SMS Cost
								</Title>
							}
							value={totalSmsCost ? totalSmsCost : 0}
							prefix={
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: 35,
										width: 35,
										borderRadius: 35,
										fontSize: 16,
										color: "#ffff",
										backgroundColor: "#0089ff",
										marginRight: 15,
									}}>
									<BiMoney />
								</div>
							}
						/>
					</Col>
				</Row>
			</Purify>
		</Card>
	)
}

export default ActivityTrackerChart
