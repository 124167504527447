export const adminUuid = "01f0c4d2-785e-4e3f-b224-96021bb555ce"
export let baseTitle = ""
export const smsCampaignPasteNumberMaxLimit = 2000
export const smsCharacterCount = 160
export const smsMaxCharacterLimit = 850
export const customPropertyDetector = "###"
export const alertTimeOut = 2000
export const currencySign = "৳"

//! SAMPLE FILE PATH
export const contactSampleFilePath = "/xls/SAMPLE_CONTACT_IMPORT.csv"
export const numberSampleFilePath =
	"/xls/SAMPLE_CREATE_CAMPAIGN_NUMBER_ONLY.csv"
export const numberWithBodySampleFilePath =
	"/xls/SAMPLE_CREATE_CAMPAIGN_NUMBER_WITH_BODY.csv"
export const enterpriseApiDocumentation =
	"/pdf/enterprise-api-documentation-v2.pdf"
export const enterpriseApiSpecification = "/pdf/api-specification-v2.pdf"

//! File Required Headers
export const numberFileRequiredHeaders = ["number"]
export const numberWithBodyFileRequiredHeaders = ["number", "body"]
export const contactImportFileRequiredHeaders = [
	"number",
	"firstName",
	"lastName",
	"company",
	"address",
	"gender",
	"email",
]
