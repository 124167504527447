import axios, { AxiosError } from "axios"

import { ENV } from "../../../ENV"
import { notification } from "antd"
import { storage } from "@shared/utils"

const headers = {
	Accept: "application/json",
	"Content-Type": "application/json",
	"X-Request-ID": 124,
	"X-Country-Code": "BD",
	"X-Client-Name": "CRM",
	"X-Client-Version": 123,
}
export const CoreAxiosWithOutInstanceV2 = axios.create({
	baseURL: ENV.ENTERPRISE_END_POINT,
	timeout: 60000,
	headers,
})

CoreAxiosWithOutInstanceV2.interceptors.response.use(
	(response) => {
		let isBlackList =
			ENV.NOTIFICATION_BLACKLIST_END_POINT.filter((x) => {
				return response.config.url.includes(x)
			}).length > 0
		if (response?.data?.success === true && !isBlackList) {
			switch (response.config.method.toUpperCase()) {
				case "POST":
					notification.success({
						message: "Created Success",
						type: "success",
						duration: 2000,
					})
					return response

				case "PUT":
					notification.success({
						message: "Updated Success",
						type: "success",
						duration: 2000,
					})
					return response

				case "DELETE":
					notification.success({
						message: "Deleted Success",
						type: "success",
						duration: 2000,
					})
					return response
			}
		} else {
			if (typeof response?.data?.error === "string") {
				notification.error({ message: response?.data?.error })
			}
		}
		return response
	},
	(error: AxiosError) => {
		try {
			if (error?.response?.status === 401) {
				storage.clear()
				window.location.assign((window.location.origin as unknown) as string)
			} else if (error?.response?.data?.errorMessage) {
				notification.error({ message: error?.response?.data?.errorMessage })
			}
		} catch (error) {
			return error
		}
	}
)
