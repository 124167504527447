import { IChangePassword, IOptConfirm } from "@shared/interfaces"

import { CoreAxiosInstanceV2 } from "@shared/config"
import { CoreAxiosWithOutInstanceV2 } from "./../config/axios/core.withoutauth.axios.v2"

const END_POINT = "/auth/"

export const AuthService = {
	refreshToken(token: string): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}refreshToken`, {
			token,
		})
	},
	forgetPasswordCheck(email: string): any {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}forgetPassword/check`, {
			email,
		})
	},
	forgetPasswordConfirm(payload: IOptConfirm): any {
		return CoreAxiosWithOutInstanceV2.post(
			`${END_POINT}forgetPassword/confirm`,
			payload
		)
	},
	changePassword(payload: IChangePassword): any {
		return CoreAxiosWithOutInstanceV2.post(
			`${END_POINT}changePassword`,
			payload
		)
	},
}
